import { ChangeEvent, ChangeEventHandler, FC, FocusEventHandler, useEffect, useState } from "react";
import { bem } from "../util/bem";

import "./TextInput.scss";

const validationDebounce = 1.0; // Wait til user stops typing for this long before validating for the first time

interface Props { 
  placeholder?: string;
  initial?: string;
  autoFocus?: boolean;

  validator?: { // TODO: find a better name for these
    match?: RegExp | RegExp[]; // make sure value is one of these
    reject?: RegExp | RegExp[]; // make sure value is NOT one of these 
  }

  onchange?: ChangeEventHandler<HTMLInputElement>;
  onfocus?: FocusEventHandler<HTMLInputElement>;
  onblur?: FocusEventHandler<HTMLInputElement>;
  
  focusPopup?: FC;
  validatorHint?: string;
  name?: string;
}

export const TextInput: FC<Props> = (props: Props) => {
  const { 
    placeholder, initial, autoFocus, validator, name,
    onchange, onfocus, onblur,
    validatorHint,
  } = props;

  const validate = (input: string): boolean => {
    const match = validator?.match;
    const reject = validator?.reject;
    
    let matched = true;
    if (match) {
      if (Array.isArray(match)) {
        const patternMatches = match.map((pattern) => pattern.test(input))
        matched = patternMatches.includes(true);
      } else {
        matched = match.test(input);
      }
    }
    
    let rejected = false;
    if (reject) {
      if (Array.isArray(reject)) {
        const patternMatches = reject.map((pattern) => !pattern.test(input))
        rejected = patternMatches.includes(true);
      } else {
        rejected = !reject.test(input);
      }
    }

    return (matched && !rejected);
  }

  const initialValid = (validator !== undefined) && (initial !== undefined) && (initial !== "") 
          ? validate(initial) 
          : null;

  const [value, setValue] = useState<string>(initial ? initial : "");
  const [valid, setValid] = useState<boolean|null>(initialValid);
  const [focused, setFocused] = useState<boolean>(autoFocus ? autoFocus : false);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
    
    if (onchange) {
      onchange(event);
    }    
  }

  const onFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    setFocused(true);

    if (onfocus) {
      onfocus(event);
    }
  }

  const onBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    setFocused(false);

    if (validator) {
      if (event.target.value !== "") {
        setValid(validate(event.target.value));
      } else {
        setValid(null);
      }
    }

    if (onblur) {
      onblur(event);
    }
  }

  const className = bem(
    "TextInput", 
    "input", 
    value === "" ? "empty" : undefined,
    validator && valid !== null
      ? valid
        ? "valid"
        : "invalid"
      : undefined,
  );

  const sidePopup = valid === false
    ? (
      <span className={bem("TextInput", "popup")}>
        {validatorHint}
      </span>
    )
    : null;

  return (
    <div className={bem("TextInput")}>
      <input 
        placeholder={placeholder}
        autoFocus={autoFocus}
        className={className}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        name={name}
      />
      {sidePopup}
    </div>
  );
}