import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { bem } from "../util/bem";

import "./ProductPage.scss"

const $bem = bem.bind(undefined, "ProductPage");

interface SpecItemProps {
  name: string;
  description: string;
  footnote?: string;
}

export const SpecItem: FC<SpecItemProps> = (props: SpecItemProps) => {
  const { name, description, footnote } = props;

  return (
    <div>
      <span>{name}<sup>{footnote ? footnote : "  "}</sup>: </span>
      <span>{description}</span>
    </div>
  );
}

export const ProductPage: FC = () => {
  return (
    <div className={$bem(undefined, "multi")}>
      <img 
        className={$bem("productImage", "raw")} 
        src="/res/img/products/background-raw.png" 
        alt="" 
      />
      <img 
        className={$bem("productImage", "enhanced")} 
        src="/res/img/products/background-enhanced.png" 
        alt="" 
      />
      <img 
        className={$bem("productImage", "events")} 
        src="/res/img/products/background-events.png" 
        alt="" 
      />
      <NavLink className={$bem("productLink")} to="/products/eagle-eye">
        <div className={$bem("productContainer")}>
          Eagle Eye
        </div>
      </NavLink>
      <NavLink className={$bem("productLink")} to="/products/hawkeye">
        <div className={$bem("productContainer")} >
          Hawkeye
        </div>
      </NavLink>
    </div>
  );
}