import { FC, useEffect, useRef, useState } from "react"

import { v4 as uuid } from "uuid";

import "./YouTubeEmbed.scss"

interface Props {
  name?: string;
  embedId: string;
}

const mmPerInch = 25.6

export const YouTubeEmbed: FC<Props> = (props: Props) => {
  const calcHeight = () => {
    return window.innerHeight - (window.devicePixelRatio * 96 * (20/mmPerInch))
  }

  const [frameResolution, setFrameResolution] = useState<any>({
    width: window.innerWidth,
    height: calcHeight(),
  })

  const embedRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const resize = () => {
      const elem = embedRef.current;
      if (elem) {
        elem.width = window.innerWidth.toString();
        elem.height = calcHeight().toString()
      }
    }

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    }
  }, [embedRef]);

  return (
    <iframe 
      ref={embedRef}
      className="YouTubeEmbed__frame"
      width={frameResolution.width} 
      height={frameResolution.height}
      src={`https://www.youtube.com/embed/${props.embedId}?enablejsapi=1&rel=0`}
      title={`YouTube video player: ${props.name ? props.name : uuid()}`} 
      frameBorder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      allowFullScreen
    />
  );
}