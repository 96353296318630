import { ChangeEventHandler, FC, useState } from "react";
import { Icon } from "../components/Icon";
import { TextInput } from "../components/TextInput";
import { IconType } from "../model/icon/IconType";
import { bem } from "../util/bem";

import "./ContactPage.scss"

const contactBEM = bem.bind(this, "ContactPage")

const Location: FC = () => {
  return (
    <div className={contactBEM("section", "location")}>
      <div className={contactBEM("sectionTitle", "location")}>
        Where we're located
      </div>
      <div className={contactBEM("sectionBody", "location")}>
        <iframe 
          title="Google Maps Office Embed"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.7090387181424!2d151.20525921521028!3d-33.87138868065548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae3e57c1a6f9%3A0xb21b17e7f81fc508!2s478%20George%20St%2C%20Sydney%20NSW%202000!5e0!3m2!1sen!2sau!4v1673842310899!5m2!1sen!2sau" 
          width="600" 
          height="450" 
          className={contactBEM("map")}
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade" 
        />
        <div className={contactBEM("info")}>
        <div className={contactBEM("infoLine")}>
            <span className={contactBEM("infoIcon")}>
              <Icon type={IconType.Location} />
            </span>
            <span className={contactBEM("infoText")}>
              Level 4 / 478 George St, Sydney 2000, NSW, Australia
            </span>
          </div>
          <div className={contactBEM("infoLine")}>
            <span className={contactBEM("infoIcon")}>
              <Icon type={IconType.Phone} />
            </span>
            <span className={contactBEM("infoText")}>
              +61 2 8315 2125
            </span>
          </div>
          <div className={contactBEM("infoLine")}>
            <span className={contactBEM("infoIcon")}>
              <Icon type={IconType.Email} />
            </span>
            <span className={contactBEM("infoText")}>
              info@cuvos.com.au
            </span>
          </div>
          <div className={contactBEM("infoLine")}>
            <span className={contactBEM("infoIcon")}>
              <Icon type={IconType.Building} />
            </span>
            <span className={contactBEM("infoText")}>
              ABN: 83 636 366 562
            </span>
          </div>
        </div>
      </div>
    </div>
 );
}


export const ContactPage: FC = () => {

  return (
    <div className={contactBEM()}>
      <Location />
      <div>
        <div className={contactBEM("help")}>
          Please contact us if you would like to know more.
        </div>
        <form action="/send_email.php" method="POST" className={contactBEM("form")}>
          <div className={contactBEM("formElement")}>
            <label className={contactBEM("label")}>Name</label>
            <TextInput name="Name"/>
          </div>

          <div className={contactBEM("formElement")}>
            <label className={contactBEM("label")}>Email</label>
            <TextInput 
              validator={{match: /^.+@.+\..+$/}}
              validatorHint="Must be an email address"
              name="Email"
            />
          </div>

          <div className={contactBEM("formElement")}>
            <label className={contactBEM("label")}>Message</label>
            <textarea           
              // FIXME: This is a hack to get basic styling on the text area
              className={contactBEM("message") + " TextInput__input"}  
              name="Message"
            />
          </div>

          <input type="submit" className={`${contactBEM("button", "submit")} ${contactBEM("formElement")}`} />
        </form>
      </div>
      
    </div>
  );
}

export default ContactPage;