import { MouseEventHandler, SyntheticEvent } from "react";
import { lookupIconInfo } from "../model/icon/IconInfo";
import { IconType } from "../model/icon/IconType";
import { bem } from "../util/bem";

import "./Icon.scss";

interface Props {
  type: IconType;
  onClick?: MouseEventHandler<HTMLImageElement>;
  className?: string
}

export function Icon(props: Props) {
  const iconInfo = lookupIconInfo(props.type);

  return (
    <img 
      className={`${bem("Icon", undefined, iconInfo.type)} ${props.className}`} 
      alt="" 
      src={iconInfo.location} 
      onClick={props.onClick}
    />
  )
}