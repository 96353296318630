import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { Route } from 'react-router';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
