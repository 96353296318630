import { HomePage } from './pages/HomePage';
import { Nav } from './components/NavigationView';

import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutPage from './pages/AboutPage';
import { GalleryPage } from './pages/GalleryPage';
import ContactPage from './pages/ContactPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { ProductPage } from './pages/ProductPage';
import { EagleEyeProductPage } from './pages/EagleEyeProductPage';
import { HawkeyeProductPage } from './pages/HawkeyeProductPage';

function App() {
  return (
    <div className="App">
      <Nav />
      <BrowserRouter window={window}>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/about" element={<AboutPage />}/>
          <Route path="/gallery" element={<GalleryPage />}/>
          <Route path="/contact" element={<ContactPage />}/>
          <Route path="/products" element={<ProductPage />}/>
          <Route path="/products/eagle-eye" element={<EagleEyeProductPage />}/>
          <Route path="/products/hawkeye" element={<HawkeyeProductPage />}/>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
