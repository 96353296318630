import { FC, useState } from "react";

import { bem } from "../util/bem";
import { YouTubeEmbed } from "./YouTubeEmbed";

import "./Carousel.scss";

interface Props {
  videoIds: string[];
}

interface ItemProps {
  id: string;
  currentIndex: number;
  index: number;
}

interface PipProps {
  currentIndex: number;
  index: number;
}

const CarouselItem: FC<ItemProps> = (props: ItemProps) => (
  <div
    className={bem("Carousel", "item")}
    style={{left: `${100*(props.index - props.currentIndex)}vw`}}
  >
    <YouTubeEmbed embedId={props.id} />
  </div>
);

export const Carousel: FC<Props> = (props: Props) => {
  const [currentIndex, updateCurrentIndex] = useState<number>(0);

  const setCurrentIndex = (index: number) => {
    if (index >= 0) {
      updateCurrentIndex(index % props.videoIds.length);
    } else if (index === -1) {
      updateCurrentIndex(props.videoIds.length - 1);
    }
  }

  const CarouselPip: FC<PipProps> = (props: PipProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={bem("Carousel", "pip", props.currentIndex === props.index ? "active" : undefined)}
      viewBox="0 0 300 300"
      onClick={() => setCurrentIndex(props.index)}
    >
      <circle
        cx={150}
        cy={150}
        r={140}
        style={{zIndex: 1000}}
      />
    </svg>
  );

  const leftArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={bem("Carousel", "arrow", "left")}
      viewBox="0 0 320 512"
      onClick={() => {setCurrentIndex(currentIndex - 1)}}
    >
      <path 
        d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 
           12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 
           246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 
           0l-160 160z"
      />
    </svg>
  )

  const rightArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={bem("Carousel", "arrow", "right")}
      viewBox="0 0 320 512"
      onClick={() => {setCurrentIndex(currentIndex + 1)}}
    >
      <path 
        d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 
           12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 
           118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 
           160z"
      />
    </svg>
  )

  const pips = [...Array(props.videoIds.length)].map((_, i) => (
      <CarouselPip key={`CarouselPip__${i}`} index={i} currentIndex={currentIndex} />
  ));

  const items = props.videoIds.map((id, index) => (
    <CarouselItem
      key={id}
      id={id}
      index={index}
      currentIndex={currentIndex}
    />
  ))

  return (
    <div className={bem("Carousel")}>
      <div className={bem("Carousel", "container")}>
        {items}
        {leftArrow}
        {rightArrow}
        <div className={bem("Carousel", "pipContainer")}>
          {pips}
        </div>
      </div>
    </div>
  )
}