import { FC } from "react";
import { bem } from "../util/bem";

import "./AboutPage.scss"

const aboutPageBEM = bem.bind(this, "AboutPage");

const About: FC = () => {
  return (
    <div className={aboutPageBEM("section", "about")}>
      <div className={aboutPageBEM("sectionTitle", "about")}>
        Who we are
      </div>
      <div className={aboutPageBEM("sectionBody")}>
        <div className={aboutPageBEM("sectionBlock", "about")}>
          <p className={aboutPageBEM("paragraph", "about")}>
            Cuvos is an Australian owned and operated company specialising in
            sensor technologies.
          </p>
          <p className={aboutPageBEM("paragraph", "about")}>
            Our team are made up of experienced business professionals,
            engineers and academics. Our engineering teams include leaders in the
            fields of high-speed digital FPGA and ASIC design, neuromorphic
            engineering, machine learning, and algorithm development.
          </p>
          <p className={aboutPageBEM("paragraph", "about")}>
            Having previously grown a cutting edge technology company to its
            successful acquisition, the management team at Cuvos understand
            what is required to develop and foster revolutionary technology.
          </p>
        </div>
      </div>
    </div>
  );
}

const AboutBusiness: FC = () => {
  return (
    <div className={aboutPageBEM("section", "business")}>
      <div className={aboutPageBEM("sectionTitle", "business")}>
        Our business is about technology
      </div>
      <div className={aboutPageBEM("sectionBody", "business")}>
        <div className={aboutPageBEM("sectionBlock", "business")}>
          <h3 className={aboutPageBEM("idea", "business")}>
            Experts in high speed digital design
          </h3>
          <div className={aboutPageBEM("bullet", "business")}>
            Our engineers have years of experience in the development of high-
            speed, high-efficiency digital processing.
          </div>
          <h3 className={aboutPageBEM("idea", "business")}>
            Neuromorphic Technology
          </h3>
          <div className={aboutPageBEM("bullet", "business")}>
            Real-time  sensor  enhancement,  change  detection,  and  anomaly
            detection with inspiration from how animals see, hear, smell, and
            touch.
          </div>
          <h3 className={aboutPageBEM("idea", "business")}>
            Novel algorithm development
          </h3>
          <div className={aboutPageBEM("bullet", "business")}>
            Expert  scientists  with  experience  in  machine  learning  and
            computational neuroscience developing efficient, smart algorithms.
          </div>
          <h3 className={aboutPageBEM("idea", "business")}>
            Low-power, efficient design
          </h3>
          <div className={aboutPageBEM("bullet", "business")}>
            Edge-ready  engineering  design  for  portable  and  autonomous
            applications.
          </div>
        </div>
      </div>
    </div>
  );
}

export const AboutPage: FC<{}> = () => {
  return (
    <div className={aboutPageBEM()}>
      <About />
      <AboutBusiness />
    </div>
  );
}

export default AboutPage;