import { IconType } from "./IconType"

interface IconInfo {
  type: IconType;
  location: string;
}

type IconMap = {[key: string]: IconInfo};

const iconLookup: IconMap = {
  "email": {
    type: IconType.Email,
    location: "./res/img/icon/envelope-solid.svg",
  },
  "location": {
    type: IconType.Location,
    location: "./res/img/icon/location-pin-solid.svg",
  },
  "phone": {
    type: IconType.Phone,
    location: "./res/img/icon/phone-solid.svg",
  },
  "bars": {
    type: IconType.Location,
    location: "./res/img/icon/bars-solid.svg",  
  },
  "xmark": {
    type: IconType.Location,
    location: "./res/img/icon/xmark-solid.svg",
  },
  "building": {
    type: IconType.Building,
    location: "./res/img/icon/building-solid.svg"
  }
}

export function lookupIconInfo(type: IconType): IconInfo {
  return iconLookup[type];
}