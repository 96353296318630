import { FC } from "react";
import { bem } from "../util/bem";

import "./ProductPage.scss";
import "./HawkeyeProductPage.scss";
import { NavLink } from "react-router-dom";
import { SpecItem } from "./ProductPage";

const $bem = bem.bind(undefined, "ProductPage");

export const EagleEyeProductPage: FC = () => {


  return (
    <div className={$bem()}>
      <div className={$bem("section")}>
        <h2 className={$bem("heading", "product")}>Eagle Eye</h2>
        <div className={$bem("sectionBody")}>
          <p className={$bem("paragraph")}>
            The Cuvos Eagle Eye is a biologically-inspired camera which boosts 
            the dynamic range of general CMOS image sensors from ~60dB up to 
            over 120dB. Eagle Eye uses Cuvos' Neuromorphic Enhanced Sensor 
            Technology (NEST) and performs image enhancement in real-time. 
            This results in significant improvements in visual situational 
            awareness, from dark conditions ({"<"}0.3 lux) through to 
            high-glare/exposure ({">"}20,000 lux).
          </p>
          <p className={$bem("paragraph")}>
            Eagle Eye can be fitted with high density colour image sensors, as
            well as infra-red (IR), ultra-violet (UV), low-light, or other
            similar image sensors to facilitate a comprehensive input
            spectrum and associated improvements in dynamic range.
          </p>
          <p className={$bem("paragraph")}>
            Eagle Eye is ideally suited to machine learning applications where
            poor lighting might be affecting the performance of your algorithms.
            With Eagle Eye as your front-end imager, you will enjoy a significantly
            wider operating range which will improve the performance of your
            algorithms.
          </p>
          <p className={$bem("paragraph")}>
            Have a look at our <NavLink to={"/gallery"}>gallery</NavLink> to 
            see how Eagle Eye could work for you. You can also&nbsp;
            <NavLink to={"/contact"}>contact us</NavLink> for more information
            or a demonstration.
          </p>
        </div>

        <div className={$bem("sectionBody")}>
          <h3 className={$bem("heading", "spec")}>
            System Specifications (R&D Model)
          </h3>
          <div>
            <SpecItem name="Size" description="25cm × 20cm × 7.5cm" />
            <SpecItem name="Weight" description="<1 kg" />
            <SpecItem name="Power Consumption" description="<10 W (R&D Model)" />
            <SpecItem name="Output" description="raw video, enhanced video, edge/change detection views, events" />
          </div>
          <h3 className={$bem("heading", "spec")}>
            Sensor Specifications<sup>*</sup>
          </h3>
          <div>
            <SpecItem name="Sensor Size" description="2/3 inch equivalent (8.8mm x 7.0mm)" />
            <SpecItem name="Max Framerate" description="120 fps" />
            <SpecItem name="Sensor Dynamic Range" description="68.6 dB" />
            <SpecItem name="Number of effective pixels" description="2592h × 2056v" />
            <SpecItem name="Sensitivity" description="30,000 e/lux/sec (Green)" />
            <SpecItem name="Data Rate" description="up to 864 Mbps (raw video/enhanced video)" />
            <SpecItem name="Spectral Band" description="400 – 700nm RGB, 800 – 1000nm NIR" />
          </div>
        </div>
      </div>
    </div>

  );
}