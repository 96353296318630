import { FC, useEffect, useRef, useState } from "react";
import { bem } from "../util/bem";

import "./HomePage.scss";

const homePageBEM = bem.bind(this, "HomePage")

const HomeSplash: FC<{}> = () => {
  const [refSrc, setReferenceImg] = useState<string|null>(null);
  const [postSrc, setProcessedImg] = useState<string|null>(null);

  useEffect(() => {
    type CallbackType = React.Dispatch<React.SetStateAction<string | null>>;
    const fetchImgJSX = async (url: string, callback: CallbackType) => {
      const imgResponse = await fetch(url);
      const imgURL = URL.createObjectURL(await imgResponse.blob());

      callback(imgURL)
    }

    fetchImgJSX("/res/img/examples/headland-raw.png", setReferenceImg);
    fetchImgJSX("/res/img/examples/headland-enhanced.png", setProcessedImg);
  }, [setReferenceImg, setProcessedImg])

  const referenceImgJSX = refSrc
    ? (
      <img 
        className={homePageBEM("splashImage", "reference")} 
        src={refSrc} 
        alt=""
      />
    )
    : null;
  const processedImgJSX = refSrc && postSrc
    ? (
      <img 
        className={homePageBEM("splashImage", "enhanced")} 
        src={postSrc} 
        alt=""
      />
    )
    : null;

  return (
    <div className={homePageBEM("splash")}>
      <div className={homePageBEM("splashContainer")}>
        {referenceImgJSX}
        {processedImgJSX}
      </div>
      <div className={homePageBEM("splashText")}>
        <div className={homePageBEM("splashName")}>
          NEST
        </div>
        <div className={homePageBEM("splashTagline")}>
          Neuromorphic Enhancement Sensor Technology
        </div>
      </div>
    </div>
  );
}

export const HomePage: FC = () => {
  return (
    <div className={homePageBEM()}>
      <HomeSplash />
    </div>
  );
}

export default HomePage;