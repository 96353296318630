export function bem(block: string, element?: string, ...modifiers: Array<string | undefined>): string {
  const b = block;
  const be = `${block}${element ? `__${element}` : ""}`;
  const bem = [];


  if (be !== b) {
    bem.push(be);
  } else {
    bem.push(b);
  }

  if (modifiers.length > 0) {
    for (const modifier of modifiers) {
      if (modifier) {
        bem.push(`${be}_${modifier}`);
      }
    }
  }

  return bem.join(" ");
}